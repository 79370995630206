/*
 * Globe object
*/
function Globe(options) {
  var getOptions = function(options) {
    var defaults = {
      width: 1500,
      height: 1500,
      speed: -1e-2,
      stroke: "rgba(119,119,119,1)",
      fill: "#000",
      canvasSelector: "globe-container"
    };

    if(!options) {
      return defaults;
    }

    var result = defaults;
    for(var option in defaults) {
      if(option in options){
        result[ option ] = options[ option ];
      }
    }

    return result;
  };

  var initializeCanvas = function() {
    var start = Date.now();
    var sphere = {type: "Sphere"};
    var projection = d3.geo.orthographic()
        .scale(options.width / 2.1)
        .clipAngle(90)
        .translate([options.width / 2, options.height / 2]);

    // Set grid
    var graticule = d3.geo.graticule();

    // Set grid lines
    graticule.step([14, 14]);

    // Initialize canvas
    var canvas = d3.select('.wrapper').append('canvas')
        .attr("width", options.width)
        .attr("height", options.height)
        .attr("id", options.canvasSelector);

    var context = canvas.node().getContext("2d");
    var path = d3.geo.path()
        .projection(projection)
        .context(context);

    var grid = graticule();
    d3.timer(function() {
      var xn = options.speed * (Date.now() - start),
          yn = 0;

      context.beginPath();
      context.clearRect(0, 0, options.width, options.height);

      context.beginPath();
      path(sphere);
      context.lineWidth = 1;
      context.strokeStyle = options.stroke;
      context.stroke();
      context.fillStyle = options.fill;
      context.fill();
      context.save();

      projection.rotate([xn, yn - 20, -130]);

      context.beginPath();
      path(grid);

      context.lineWidth = .5;
      context.strokeStyle = options.stroke;
      context.stroke();
    });

    d3.select(self.frameElement).style("height", options.height + "px");
  };

  var initialize = function() {
    options = getOptions(options);
    initializeCanvas();
  };

  initialize();
};